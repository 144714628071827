export const sdkTypeByViewerType = {
  'wysiwyg.viewer.components.SiteButton': 'Button',
  'wysiwyg.viewer.components.PopupCloseTextButton': 'Button',
  'wysiwyg.viewer.components.PopupCloseIconButton': 'Button',
  'wysiwyg.common.components.imagebutton.viewer.ImageButton': 'Button',
  'wysiwyg.viewer.components.WPhoto': 'Image',
  'wysiwyg.viewer.components.FooterContainer': 'Footer',
  'wysiwyg.viewer.components.HeaderContainer': 'Header',
  'wysiwyg.viewer.components.WRichText': 'Text',
  'mobile.core.components.Page': 'Page',
  'wysiwyg.viewer.components.inputs.TextInput': 'TextInput',
  'wysiwyg.viewer.components.inputs.TextAreaInput': 'TextBox',
  'wysiwyg.viewer.components.inputs.DatePicker': 'DatePicker',
  'wysiwyg.viewer.components.BoxSlideShow': 'Slideshow',
  'wysiwyg.viewer.components.StripContainerSlideShow': 'Slideshow',
  'wysiwyg.viewer.components.StateBox': 'MultiStateBox',
  'wysiwyg.viewer.components.StateStrip': 'MultiStateBox',
  'wysiwyg.viewer.components.SlideShowGallery': 'Gallery',
  'wysiwyg.viewer.components.SliderGallery': 'Gallery',
  'wysiwyg.viewer.components.MatrixGallery': 'Gallery',
  'wysiwyg.viewer.components.PaginatedGridGallery': 'Gallery',
  'mobile.core.components.Container': 'Box',
  'wysiwyg.viewer.components.Group': 'Box',
  'platform.components.AppController': 'AppController',
  'wysiwyg.viewer.components.svgshape.SvgShape': 'VectorImage',
  'wysiwyg.viewer.components.VectorImage': 'VectorImage',
  'wysiwyg.viewer.components.inputs.Checkbox': 'Checkbox',
  'wixui.RichTextBox': 'RichTextBox',
  'wixui.ToggleSwitch': 'Switch',
  'wixui.AddressInput': 'AddressInput',
  'wysiwyg.viewer.components.inputs.RadioGroup': 'RadioButtonGroup',
  'wysiwyg.viewer.components.inputs.CheckboxGroup': 'CheckboxGroup',
  'wysiwyg.viewer.components.BoxSlideShowSlide': 'Slide',
  'wysiwyg.viewer.components.StripContainerSlideShowSlide': 'Slide',
  'wysiwyg.viewer.components.StateBoxState': 'State',
  'wysiwyg.viewer.components.StateBoxFormState': 'State',
  'wysiwyg.viewer.components.StateStripState': 'State',
  'wysiwyg.viewer.components.inputs.ComboBoxInput': 'Dropdown',
  'wysiwyg.viewer.components.Column': 'Column',
  'wysiwyg.viewer.components.StripColumnsContainer': 'ColumnStrip',
  'wysiwyg.viewer.components.Grid': 'Table',
  'wysiwyg.viewer.components.inputs.FileUploader': 'UploadButton',
  'wysiwyg.common.components.anchor.viewer.Anchor': 'Anchor',
  'wysiwyg.viewer.components.tpapps.TPASection': 'IFrame',
  'wysiwyg.viewer.components.tpapps.TPAMultiSection': 'IFrame',
  'wysiwyg.viewer.components.tpapps.TPAGluedWidget': 'IFrame',
  'wysiwyg.viewer.components.HtmlComponent': 'HtmlComponent',
  'wysiwyg.viewer.components.GoogleMap': 'GoogleMap',
  'wysiwyg.viewer.components.Video': 'Video',
  'wysiwyg.viewer.components.HoverBox': 'HoverBox',
  'wysiwyg.viewer.components.Popover': 'Popover',
  'wysiwyg.viewer.components.MenuContainer': 'MenuContainer',
  'tpa.viewer.components.Freestyle': 'Gallery',
  'tpa.viewer.components.Honeycomb': 'Gallery',
  'tpa.viewer.components.Accordion': 'Gallery',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': 'Gallery',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery': 'Gallery',
  'tpa.viewer.components.Impress': 'Gallery',
  'tpa.viewer.components.Masonry': 'Gallery',
  'tpa.viewer.components.Collage': 'Gallery',
  'tpa.viewer.components.StripSlideshow': 'Gallery',
  'tpa.viewer.components.StripShowcase': 'Gallery',
  'tpa.viewer.components.Thumbnails': 'Gallery',
  'wysiwyg.viewer.components.tpapps.TPAWidget': 'IFrame',
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': 'VerticalMenu',
  'wysiwyg.viewer.components.LoginSocialBar': 'AccountNavBar',
  'wysiwyg.viewer.components.Repeater': 'Repeater',
  'wysiwyg.viewer.components.MediaContainer': 'Container',
  'wysiwyg.viewer.components.mobile.TinyMenu': 'MobileMenu',
  'wysiwyg.viewer.components.QuickActionBar': 'QuickActionBar',
  'wixui.MusicPlayer': 'AudioPlayer',
  'wixui.BarChart': 'BarChart',
  'wixui.VideoPlayer': 'VideoPlayer',
  'wixui.Pagination': 'Pagination',
  'wixui.Tags': 'Tags',
  'wixui.Slider': 'Slider',
  'wixui.Captcha': 'Captcha',
  'wixui.TimePicker': 'TimePicker',
  'wixui.RatingsDisplay': 'RatingsDisplay',
  'wixui.RatingsInput': 'RatingsInput',
  'wixui.SearchBox': 'SearchBox',
  'platform.components.AppWidget': 'AppWidget',
  'wysiwyg.viewer.components.tpapps.TPAWidgetNative': 'Native',
  'wysiwyg.viewer.components.MediaBox': 'MediaBox',
  'wixui.ProgressBar': 'ProgressBar',
  'wixui.CustomElementComponent': 'CustomElement'
}

export const sdkTypeByWidgetId = {
  '142bb34d-3439-576a-7118-683e690a1e0d': 'Gallery'
}
export const defaultSdkType = 'HiddenCollapsedElement'

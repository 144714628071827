import {parseUrl} from './urlUtils'
import _ from 'lodash'

export class RemoteGlobalsInterface {
  constructor(modelJson) {
    this._globals = _.defaults(modelJson, {
      navigation: {},
      sessionInfo: {},
      appsData: {},
      siteMemberData: {uid: null, permissions: null, onUserLogin: []},
      multilingualInfo: {isEnabled: false, currentLanguageCode: null, siteLanguages: [], onCurrentLanguageChange: []},
      appStudioWidgetData: {isApplicationStudio: false, onPropsChanged: []},
      deviceType: null,
      isMobileFriendly: null,
      lightboxContext: undefined,
      contextId: null,
      siteRevision: null,
      routerData: null,
      regionalSettings: {
        locale: null,
        language: null,
        timezone: null,
        currency: null,
        browserLocale: null
      },
      seo: {
        isInSEO: undefined
      }
    })
    this._globals.onInstanceChanged = {} //{appDefId: [callback, callback, ...],
    this._globals.onLocationChange = []
  }

  addRegionalSettings(regionalSettings) {
    this._globals.regionalSettings = regionalSettings
  }

  addNavigation(navigationData) {
    const shouldTriggerOnLocationChange = this._globals.navigation.currentPageId === navigationData.currentPageId &&
      this._globals.navigation.tpaInnerRoute !== navigationData.tpaInnerRoute
    this._globals.navigation = navigationData
    if (shouldTriggerOnLocationChange) {
      const onLocationChangeCallbacks = this._globals.onLocationChange
      _.forEach(onLocationChangeCallbacks, callback => callback({path: this.getLocation().path}))
    }
  }

  addSiteMemberData(siteMemberData) {
    const shouldTriggerOnLoginCallback = siteMemberData.uid && siteMemberData.uid !== this._globals.siteMemberData.uid
    _.assign(this._globals.siteMemberData, siteMemberData)
    if (shouldTriggerOnLoginCallback) {
      const onUserLoginCallbacks = this._globals.siteMemberData.onUserLogin
      _.forEach(onUserLoginCallbacks, callback => {
        callback(siteMemberData)
      })
    }
  }

  addAppsData(newAppsData) {
    const oldAppsData = this._globals.appsData
    _.forEach(this._globals.onInstanceChanged, (callbacks, appDefinitionId) => {
      const oldInstance = _.get(oldAppsData, [appDefinitionId, 'instance'])
      const newInstance = _.get(newAppsData, [appDefinitionId, 'instance'])
      if (oldInstance !== newInstance) {
        _.invokeMap(callbacks, 'call', null, {instance: newInstance})
      }
    })
    this._globals.appsData = newAppsData
  }

  addOnLocationChangeCallback(callback) {
    if (!this._globals.onLocationChange) {
      this._globals.onLocationChange = []
    }
    this._globals.onLocationChange.push(callback)
  }

  addOnUserLoginCallback(callback) {
    this._globals.siteMemberData.onUserLogin.push(callback)
  }

  addSessionInfoProp(addSessionInfoProp) {
    _.assign(this._globals.sessionInfo, addSessionInfoProp)
  }

  addSiteRevision(siteRevision) {
    this._globals.siteRevision = siteRevision
  }

  addDeviceType(deviceType) {
    this._globals.deviceType = deviceType
  }

  addIsMobileFriendly(isMobileFriendly) {
    this._globals.isMobileFriendly = isMobileFriendly
  }

  addLightboxContext(lightboxContext) {
    this._globals.lightboxContext = lightboxContext
  }

  addContextId(contextId) {
    this._globals.contextId = contextId
  }

  addMultilingualInfo(multilingualInfo) {
    const shouldTriggerOnCurrentLanguageChange = multilingualInfo.currentLanguageCode &&
      multilingualInfo.currentLanguageCode !== this._globals.multilingualInfo.currentLanguageCode

    _.assign(this._globals.multilingualInfo, multilingualInfo)
    if (shouldTriggerOnCurrentLanguageChange) {
      const onCurrentLanguageChangeCallbacks = this._globals.multilingualInfo.onCurrentLanguageChange
      _.forEach(onCurrentLanguageChangeCallbacks, callback => {
        callback(multilingualInfo)
      })
    }
  }

  addOnCurrentLanguageChangeCallback(callback) {
    this._globals.multilingualInfo.onCurrentLanguageChange.push(callback)
  }

  addLivePreviewMode(livePreviewMode) {
    this._globals.livePreviewMode = livePreviewMode
  }

  addRouterData(routerData) {
    this._globals.routerData = routerData
  }


  //Getters

  getRouterData(appDefinitionId) {

    const isAppRouterData = () => {
      const isUserCode = _.isUndefined(appDefinitionId)
      const isWixCodeRouter = routerAppDefId === 'wix-code'
      const isAppRouter = appDefinitionId === routerAppDefId
      return isWixCodeRouter && isUserCode || isAppRouter
    }
    if (!this._globals.routerData) {return null}
    const routerAppDefId = _.get(this._globals.routerData, 'routerDefinition.appDefinitionId')

    return isAppRouterData() ? _.cloneDeep(this._globals.routerData.routerData) : null
  }

  getAppsData() {
    return _.cloneDeep(this._globals.appsData)
  }

  getDeviceType() {
    return this._globals.deviceType
  }

  getIsMobileFriendly() {
    return this._globals.isMobileFriendly
  }

  getLightboxContext() {
    return _.cloneDeep(this._globals.lightboxContext)
  }

  getContextId() {
    return this._globals.contextId
  }

  //Getters

  getAppData(appDefinitionId) {
    return _.cloneDeep(_.get(this._globals.appsData, appDefinitionId))
  }

  getNavigationInfo() {
    return _.cloneDeep(this._globals.navigation)
  }

  getRegionalSettings() {
    return _.cloneDeep(this._globals.regionalSettings)
  }

  getSiteMemberData(appDefinitionId) {
    const siteMemberData = _.cloneDeep(this._globals.siteMemberData)
    if (appDefinitionId) {
      const appData = _.get(this._globals.appsData, appDefinitionId)
      if (appData) {
        _.assign(siteMemberData, _.pick(appData, ['instance', 'instanceId']))
      }
    }
    return siteMemberData
  }

  getMultilingualInfo() {
    return _.cloneDeep(this._globals.multilingualInfo)
  }

  getCurrentLanguageCode() {
    return this._globals.multilingualInfo.currentLanguageCode
  }

  getSiteLanguages() {
    return this._globals.multilingualInfo.siteLanguages
  }

  getIsMultilingualEnabled() {
    return this._globals.multilingualInfo.isEnabled
  }

  getSessionInfo() {
    return _.cloneDeep(this._globals.sessionInfo)
  }

  getLivePreviewMode() {
    return this._globals.livePreviewMode
  }

  addIsApplicationStudio(isApplicationStudio) {
    this._globals.appStudioWidgetData.isApplicationStudio = isApplicationStudio
  }

  getIsApplicationStudio() {
    return this._globals.appStudioWidgetData.isApplicationStudio
  }

  getSiteRevision() {
    return _.invoke(this._globals.siteRevision, 'toString') || null
  }

  addOnWidgetPropsChangedCallback(callback) {
    this._globals.appStudioWidgetData.onPropsChanged.push(callback)
  }

  triggerWidgetPropsChanged(oldProps, newProps) {
    _.forEach(this._globals.appStudioWidgetData.onPropsChanged, callback => {
      callback(_.cloneDeep(oldProps), _.cloneDeep(newProps))
    })
  }

  addOnInstanceChangedCallback(appDefinitionId, callback) {
    if (!this._globals.onInstanceChanged[appDefinitionId]) {
      this._globals.onInstanceChanged[appDefinitionId] = []
    }
    this._globals.onInstanceChanged[appDefinitionId].push(callback)
  }

  getFullUrl() {
    return this._globals.navigation.currentPageFullUrl || this._globals.navigation.baseUrl
  }

  getLocation() {
    const location = {}
    location.url = this.getFullUrl()

    const parsedUrl = parseUrl(location.url)
    const prefix = this._globals.navigation && this._globals.navigation.routerDefinition ? this._globals.navigation.routerDefinition.prefix : undefined
    const isFreeSite = this._globals.navigation.baseUrl.indexOf('wixsite.com') > 0
    const pathAsArray = parsedUrl.path.substring(1).split('/')
    const fullPath = isFreeSite ? pathAsArray.slice(1) : pathAsArray

    location.hash = parsedUrl.hash
    location.protocol = parsedUrl.protocol
    location.prefix = prefix
    location.path = prefix && fullPath[0] === prefix ? fullPath.slice(1) : fullPath
    location.query = parsedUrl.query

    return location
  }

  isInSEO() {
    return this._globals.seo.isInSEO
  }

  toJson() {
    return this._globals
  }
}
